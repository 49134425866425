body {
  scroll-padding-top: $marketing-navbar-height;
}

body.scrolled {
  .navbar-light {
    @extend .box-shadow;

    transition: 0.4s;
  }
}

h1 {
  font-size: 2rem;
  text-align: center;
}

h2 {
  font-size: 1.5rem;
  text-align: center;
}

h3 {
  font-size: 1rem;
}

h1,
h2,
h3 {
  text-underline-offset: 4px;
}

a:hover {
  text-decoration: none;
}

.card {
  @extend .box-shadow;
  @extend .mb-4;
}

#cookie-consent {
  @extend .fixed-bottom;
  @extend .py-1;

  color: $gray-600;
  background-color: $brand-dark;
  color: $primary;

  > div {
    @extend .px-4;

    max-width: 960px;
    margin: 0 auto;

    > div {
      @extend .py-1;

      float: left;

      > a {
        color: inherit;
        text-decoration: underline;
      }
    }

    > .btn {
      @extend .py-1;

      float: right;
    }
  }
}

div[data-bs-toggle='tooltip'] {
  @extend .text-primary;

  cursor: help;
}

.box-shadow {
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.05);
}

.container {
  max-width: 960px;
}

#content {
  padding-top: 0;
  padding-bottom: 4rem;
  min-height: calc(100vh - $marketing-navbar-height - $marketing-footer-height);
}

section {
  padding-top: 3rem;

  h2 {
    margin-bottom: 0;
  }

  p {
    @extend .text-muted;
  }
}

section > .row {
  padding-top: 3rem;
}

footer {
  @extend .bg-primary;
  @extend .text-center;

  height: $marketing-footer-height;
  line-height: $marketing-footer-height;
  color: #fff;

  a {
    color: #fff;
  }

  a:hover {
    @extend .text-decoration-underline;
  }
}

.btn-primary,
.btn-primary:hover,
.badge.bg-primary {
  background: linear-gradient(90deg, #00b5e0, #01cbcd, #01e0ba);
}

.btn-primary,
.btn-primary:hover {
  opacity: 1;
  transition: opacity 0.2s;
}

.btn.btn-primary:hover {
  opacity: 0.8;
}

.btn-secondary,
.btn.btn-secondary:hover {
  @extend .border-primary;

  color: $primary;
  background: $primary-light;
}

.btn.btn-secondary:hover {
  opacity: 0.8;
}

.btn.btn-primary.disabled,
.btn.btn-primary.disabled:hover,
.btn.btn-secondary.disabled,
.btn.btn-secondary.disabled:hover {
  pointer-events: all;
  cursor: not-allowed;
  opacity: 0.4;
}

.navbar-light {
  background-color: #fff;
}

.navbar {
  .navbar-collapse,
  .navbar-toggler {
    min-height: $marketing-navbar-height;
  }

  .navbar-brand {
    img {
      margin-right: 1rem;
      line-height: 0;
      width: calc(1 * $marketing-navbar-height / 2.2);
      height: calc(0.895 * $marketing-navbar-height / 2.2);
    }

    span {
      font-weight: 500;
      font-size: 1.2rem;
    }
  }

  .nav-link {
    padding: 0 0 0 2rem !important;
    line-height: $marketing-navbar-height;
    border: none;
  }

  .nav-link.active {
    color: $primary;
  }
}

#hero {
  h1 {
    line-height: 3rem;
    text-align: left;
  }

  .btn {
    @extend .me-2;
  }

  img {
    @extend .box-shadow;
    @extend .border;
    @extend .rounded;
    @extend .w-100;
  }
}

#features {
  text-align: center;

  .feature {
    i {
      @extend .brand-gradient;

      display: block;
      margin-bottom: 20px;
      font-size: 5rem;
    }

    p {
      margin-bottom: 0;
    }
  }
}

#testimonials {
  .card {
    @extend .border-primary;

    background-color: $primary-light;
  }

  .customer {
    img {
      @extend .rounded-circle;
      @extend .me-3;

      width: 40px;
    }
  }
}

#success-stories {
  .card {
    @extend .border-primary;

    background-color: $primary-light;
  }

  .image {
    img {
      @extend .border;
      @extend .border-primary;
      // @extend .border-5;
      @extend .rounded;
      @extend .mb-3;

      width: 100%;
    }
  }
}

#pricing {
  text-align: center;

  .plans {
    display: none;
  }

  .plans.active {
    display: block;
  }

  .card {
    @extend .border-primary;

    .card-header {
      @extend .border-primary;

      background-color: $primary-light;

      h4 {
        @extend .brand-gradient;
        @extend .my-1;

        i {
          @extend .me-3;
        }
      }
    }

    .card-body {
      .price {
        @extend .fs-2;

        span {
          @extend .fs-4;
          @extend .text-muted;
        }
      }

      .price-discount {
        @extend .mt-2;
        @extend .p-1;

        border-radius: $border-radius;
        background-color: #ffffcc;

        span {
          @extend .fw-bold;
          @extend .text-warning;
        }
      }

      ul {
        @extend .mt-3;
        @extend .list-unstyled;
        @extend .vstack;
        @extend .gap-2;
      }
    }
  }

  .btn {
    width: 100%;
  }
}

#cta {
  text-align: center;

  .btn {
    @extend .fs-4;
    @extend .py-2;

    min-width: 300px;
  }
}
