body.auth-layout {
  background-image: url('img/office-bg.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #f5f5f5;

  #content {
    padding-top: 20px;
    padding-bottom: 5rem; // Room for recaptcha and helpscout buttons in mobile view
  }

  div.auth {
    @extend .row;
    @extend .h-100;

    width: 400px;
    max-width: 90%;
    margin: 0 auto;
  }

  div.auth .brand {
    @extend .my-5;

    text-align: center;
  }

  div.auth .brand img {
    width: 100px;
  }

  div.auth h4 {
    @extend .my-2;
  }

  div.auth .btn.btn-primary {
    @extend .fs-5;
    @extend .py-2;

    background: linear-gradient(90deg, #00b5e0, #01cbcd, #01e0ba);
    border: none;

    opacity: 1;
    transition: opacity 0.2s;
  }

  .auth .btn.btn.btn-primary[disabled] {
    opacity: 0.5;
  }

  .auth .btn.btn-primary:hover {
    opacity: 0.8;
  }

  .grecaptcha-badge {
    bottom: 0;
    left: 0;
    margin-left: 40px;
    margin-bottom: 25px;
  }

  @include media-breakpoint-down(sm) {
    .grecaptcha-badge {
      margin-left: 15px;
      margin-bottom: 0;
    }
  }
}
